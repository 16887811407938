import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import SearchBar from './SearchBar';
import FilterBar from './FilterBar';
import PopularNovels from './PopularNovels';
import RelatedNovels from './individualBook/RelatedNovels';
import RelatedArchives from './individualBook/RelatedArchives';
import MainDetail from './individualBook/MainDetail';
import RelatedDataVis from './individualBook/RelatedDataVis';
import RelatedBriefs from './individualBook/RelatedBriefs';
import RelatedPodcasts from './individualBook/RelatedPodcasts';
import { linkUrl } from './linkUrl';
import './booksearch.css';
import { BookIcon, FeaturedIcon } from './Icons'; // Assuming SVGs are moved to Icons.js

const featuredNovelNames = [
  "The Fortune Men",
  "The Color Purple",
  "Swing Time",
  "Beloved",
  "Americanah"
];

const BookSearch = () => {
  const { bookId } = useParams();  // Get bookId from URL if present
  const navigate = useNavigate();
  const [selectedBook, setSelectedBook] = useState(null);
  const [results, setResults] = useState([]);
  const [featuredNovels, setFeaturedNovels] = useState([]);
  const [isDisplayingFeatured, setIsDisplayingFeatured] = useState(true);
  const [levels, setLevels] = useState([]);
  const [categories, setCategories] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [genders, setGenders] = useState([]);
  const [locations, setLocations] = useState([]);
  const [awards, setAwards] = useState([]);
  const [filters, setFilters] = useState({});
  const [shouldPerformSearch, setShouldPerformSearch] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  useEffect(() => {
    const fetchFeaturedNovels = async () => {
      try {
        const queries = featuredNovelNames.map(name => ({
          match: { "Book title.keyword": name }
        }));
        const response = await axios.post(linkUrl, {
          query: {
            bool: {
              should: queries,
              minimum_should_match: 1
            }
          },
          size: featuredNovelNames.length
        });
        setFeaturedNovels(response.data.hits.hits);
      } catch (error) {
        console.error('Error fetching featured novels from Elasticsearch:', error);
      }
    };

    fetchFeaturedNovels();
  }, []);

  useEffect(() => {
    const fetchPeriods = async () => {
      const response = await axios.post(linkUrl, {
        size: 0,
        aggs: {
          unique_time_periods: {
            terms: {
              field: "Time period.keyword",
              size: 10
            }
          }
        }
      });

      const fetchedPeriods = response.data.aggregations.unique_time_periods.buckets.map(bucket => bucket.key);
      setPeriods(fetchedPeriods);
    };

    fetchPeriods();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await axios.post(linkUrl, {
        size: 0,
        aggs: {
          all_categories: {
            terms: {
              field: "Categories.keyword",
              size: 50
            }
          }
        }
      });

      const fetchedCategories = response.data.aggregations.all_categories.buckets.map(bucket => bucket.key);
      setCategories(fetchedCategories);
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchGenders = async () => {
      const response = await axios.post(linkUrl, {
        size: 0,
        aggs: {
          unique_genders: {
            terms: {
              field: "Gender of author.keyword",
              size: 10
            }
          }
        }
      });

      const fetchedGenders = response.data.aggregations.unique_genders.buckets.map(bucket => bucket.key);
      setGenders(fetchedGenders);
    };

    fetchGenders();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      const response = await axios.post(linkUrl, {
        size: 0,
        aggs: {
          unique_locations: {
            terms: {
              field: "Location/region.keyword",
              size: 50
            }
          }
        }
      });

      const fetchedLocations = response.data.aggregations.unique_locations.buckets.map(bucket => bucket.key);
      setLocations(fetchedLocations);
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchAwards = async () => {
      const response = await axios.post(linkUrl, {
        size: 0,
        aggs: {
          unique_awards: {
            terms: {
              field: "Major award(s).keyword",
              size: 50
            }
          }
        }
      });

      const fetchedAwards = response.data.aggregations.unique_awards.buckets.map(bucket => bucket.key);
      setAwards(fetchedAwards);
    };

    fetchAwards();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(linkUrl, {
          size: 0,
          aggs: {
            unique_levels: {
              terms: {
                field: "Level.keyword",
                size: 10
              }
            }
          }
        });
        const fetchedLevels = response.data.aggregations.unique_levels.buckets.map(bucket => bucket.key);
        setLevels(fetchedLevels);
      } catch (error) {
        console.error('Error fetching levels:', error);
      }
    };

    fetchData();
  }, []);

  // Populate filters when new options are fetched
  useEffect(() => {
    const updatedFilters = { ...filters };
    categories.forEach(category => updatedFilters[category] = false);
    levels.forEach(level => updatedFilters[level] = false);
    periods.forEach(period => updatedFilters[period] = false);
    genders.forEach(gender => updatedFilters[gender] = false);
    locations.forEach(location => updatedFilters[location] = false);
    awards.forEach(award => updatedFilters[award] = false);
    setFilters(updatedFilters);
  }, [categories, levels, periods, genders, locations, awards]);

  const areFiltersActive = () => {
    return Object.values(filters).some(value => value);
  };

  const handleBookClick = (book) => {
    setSelectedBook(book._source);  // Set the selected book data
    setIsDisplayingFeatured(false);
    navigate(`/books/${book._id}`);  // Use book._id to set the URL correctly
  };

  // Handle tag clicks for filtering
  const onTagClick = (tag, field) => {
    const updatedFilters = { ...filters };
    Object.keys(filters).forEach(key => updatedFilters[key] = false);  // Reset all filters
    updatedFilters[tag] = true;  // Activate the clicked tag filter
    onSearch(tag, updatedFilters);  // Search with the tag
    setSearchTerm(tag);  // Update search term
  };

  // Load book if bookId is present in the URL
  useEffect(() => {
    if (bookId) {
      const fetchBookById = async () => {
        try {
          const response = await axios.post(linkUrl, {
            query: {
              match: { "_id": bookId }
            }
          });

          // Check if there are any hits in the response
          if (response.data.hits && response.data.hits.hits.length > 0) {
            const book = response.data.hits.hits[0];
            setSelectedBook(book._source);  // Set the selected book
            setIsDisplayingFeatured(false); // Hide featured novels
          } else {
            console.error('No book found with the provided ID.');
            setSelectedBook(null);
          }
        } catch (error) {
          console.error('Error fetching book by ID:', error);
          setSelectedBook(null);
        }
      };

      fetchBookById();
    }
  }, [bookId]);

  const onSearch = async (searchTerm, filters) => {
    // Check if there are no active filters and no search term
    if (!areFiltersActive() && !searchTerm) {
      setIsDisplayingFeatured(true);
      return;
    }

    setIsDisplayingFeatured(false); // Hide featured novels
    setSelectedBook(null);

    const filterQueries = Object.entries(filters).reduce((acc, [key, value]) => {
      if (value) {
        if (categories.includes(key)) {
          acc.push({ term: { "Categories.keyword": key } });
        } else if (levels.includes(key)) {
          acc.push({ term: { "Level.keyword": key } });
        } else if (genders.includes(key)) {
          acc.push({ term: { "Gender of author.keyword": key } });
        } else if (periods.includes(key)) {
          acc.push({ term: { "Time period.keyword": key } });
        } else if (locations.includes(key)) {
          acc.push({ term: { "Location/region.keyword": key } });
        } else if (awards.includes(key)) {
          acc.push({ term: { "Major award(s).keyword": key } });
        }
      }
      return acc;
    }, []);

    const searchBody = {
      size: 100,
      query: {
        bool: {
          should: [
            { multi_match: { query: searchTerm, fields: ["Book title^3", "Name as it appears", "Summary", "Categories", "Location/region", "Major award(s)", "Time period", "Level", "Gender of author"], fuzziness: "AUTO" } }
          ],
          filter: filterQueries // Filter will be empty if no filters are active
        }
      }
    };

    try {
      const response = await axios.post(linkUrl, searchBody);
      setResults(response.data.hits.hits);
    } catch (error) {
      console.error('Error during Elasticsearch query:', error);
    }
  };

  const onFilterChange = (filterName, filterValue) => {
    const updatedFilters = { ...filters, [filterName]: filterValue };
    setShouldPerformSearch(true);
    onSearch(searchTerm, updatedFilters);
    setFilters(updatedFilters);

    // Check if no filters are active
    if (!areFiltersActive()) {
      setSearchTerm(''); // Clear the search term to return to the featured page
      setFilters(updatedFilters);
      setIsDisplayingFeatured(true); // Show the featured novels
      return;
    }
  };

  useEffect(() => {
    if (shouldPerformSearch) {
      onSearch(searchTerm, filters);
      setShouldPerformSearch(false);
    }
  }, [searchTerm, filters, shouldPerformSearch]);



  return (
    <div className='content'>
      {isVisible && (
        <button
          className="back-to-top-button"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          aria-label="Back to top">
          <span className="back-to-top-text">Back to Top</span>
        </button>
      )}
      <div className='filter-bar'>
        <div className='filter-bar-header'>
          <BookIcon /> {/* Use BookIcon */}
          Novel Generator Machine
        </div>
        <div className='filter-bar-content'>
          <SearchBar setSearchTerm={setSearchTerm} setShouldPerformSearch={setShouldPerformSearch} />
          <FilterBar 
            onFilterChange={onFilterChange} 
            filters={filters} 
            categories={categories} 
            levels={levels} 
            periods={periods} 
            genders={genders} 
            locations={locations} 
            awards={awards} 
          />
        </div>
      </div>

      {selectedBook ? (
        <div className='focusBook'>
          <MainDetail selectedBook={selectedBook} onTagClick={onTagClick} />
          <RelatedNovels selectedBook={selectedBook} onNovelClick={handleBookClick} />
          <RelatedDataVis selectedData={selectedBook} />
          <RelatedBriefs selectedData={selectedBook} />
          <RelatedPodcasts selectedBook={selectedBook} />
          <RelatedArchives selectedBook={selectedBook} />
        </div>
      ) : (
        <div className='search-results'>
          {isDisplayingFeatured && (
            <>
              <p className="featured-subtitle">
                <FeaturedIcon /> {/* Use FeaturedIcon */}
                Featured Novels
              </p>
              <div className="featured-novels-container">
                {featuredNovels.map((novel) => (
                  <div key={novel._id} className="featured-novel" onClick={() => handleBookClick(novel)}>
                    {novel._source.Bookcover && (
                      <img src={novel._source.Bookcover.replace('.', '')} alt={`Cover of ${novel._source["Book title"]}`} />
                    )}
                    <div className="novel-info">
                      <i><p>{novel._source["Book title"]}</p></i>
                      <p>{novel._source["Name as it appears"]}</p>
                      <p>{novel._source["Publication year"]}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {!isDisplayingFeatured && (
            <>
              <p className="featured-subtitle"> Search Results</p>
              <div className='search-results-container'>
                {results.map((result) => (
                  <div key={result._id} className="searched-novel" onClick={() => handleBookClick(result)}>
                    {result._source.Bookcover && (
                      <img src={result._source.Bookcover.replace('.', '')} alt={`Cover of ${result._source["Book title"]}`} />
                    )}
                    <div className="novel-info">
                      <i><p>{result._source["Book title"]}</p></i>
                      <p>{result._source["Name as it appears"]}</p>
                      <p>{result._source["Publication year"]}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <PopularNovels isDisplayingFeatured={isDisplayingFeatured} onNovelClick={handleBookClick} />
        </div>
      )}
    </div>
  );
};

export default BookSearch;
