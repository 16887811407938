import React from 'react';
import './Footer.css'; // Assume you have a separate CSS file for the footer
import { NavLink, Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="footer">
        
        <div className="footer-top">
        <div className='footer-first-half'>
        <Link to="/" className='logo-link-footer'>
            <img src="/logofoot.png" alt="Logo" className="logo-foot" />
        </Link>
          <div className='footer-first-half-left'>
            <div className="footer-links">
              <p>CONTACT: <a>BLACKLIT@KU.EDU</a></p>
            </div>
            <div className="social-icons-footer">
              <a href="https://twitter.com" className="social-icon"><i className="fab fa-twitter"></i></a>
              <a href="https://facebook.com" className="social-icon"><i className="fab fa-facebook-f"></i></a>
              <a href="https://instagram.com" className="social-icon"><i className="fab fa-instagram"></i></a>
            </div>
          </div>
          
        </div>
        
          <div className="subscription-social-container">
            <p>© 2024 Black lit network</p>
          </div>
        </div>
        <div className="footer-bottom">
            <div className="footer-funding">
            <p>Funded by</p>
            <img src="/mellon.png" alt="Mellon Foundation" />
            <img src="/neh.png" alt="National Endowment for the Humanities" />
            </div>
            <div className="footer-partners">
            <p>Partnered Universities</p>
            <img src="/kulogo.png" alt="KU" />
            <img src="/texaslogo.png" alt="TU" />
            <img src="/SIU.png" alt="SIU" />
            </div>
        </div>
    </footer>
  );
};

export default Footer;
