// IntermediatePage.js
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// import RelatedNovels from './individualBook/RelatedNovels';
// import RelatedBriefs from './individualBook/RelatedBriefs';
// import RelatedPodcasts from './individualBook/RelatedPodcasts';


const IntermediatePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { link } = location.state || {};
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');

    useEffect(() => {
        if (!link) {
            navigate('/datavis'); // Redirect back if no link data is found
        }
    }, [link, navigate]);

    const togglePopup = (url = '') => {
        setIframeUrl(url);
        setIsPopupVisible(!isPopupVisible);
    };

    if (!link) return null;

    return (
        <div className="intermediate-container">
            <h2>{link.title}</h2>
            <div className="related-content">
                {/* <RelatedNovels selectedBook={link} />
                <RelatedBriefs selectedData={link} />
                <RelatedPodcasts selectedBook={link} /> */}
            </div>
            <button onClick={() => togglePopup(link.url)} className="show-popup-button">Show Visualization</button>

            {isPopupVisible && (
                <div className="PopupOverlay">
                    <div className="PopupContent">
                        <iframe src={iframeUrl} name="iframe-content" width="100%" height="100%"></iframe>
                        <button className="CloseButton" onClick={() => togglePopup('')}>X</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default IntermediatePage;
